
import { useState, useEffect } from 'react'
import { Container, Header, Content, Row, Col, Button, Icon, IconButton, Timeline, Panel, Loader } from 'rsuite';
import { useParams, useHistory } from "react-router-dom";

import MembersAreaService from '../../services/MemberArea.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import hd from '../../assets/svg/hd.svg'

import './style.css'
import { getFormatedTimeFromSeconds } from '../../utils/formatSeconds.utils';
import AuthService from '../../services/Authentication.service';

const CoursePage = () => {
    const { courseId } = useParams()
    const history = useHistory()

    const [cover, setCover] = useState({})
    const [userId, setUserId] = useState(null)
    const [schoolId, setSchoolId] = useState(null)
    const [canGetCertificate, setCanGetCertificate] = useState(false)

    const [metadata, setMetadata] = useState({
        course: {},
        modules: {}
    })

    const [courseProgress, setCourseProgress] = useState({
        courseDonePercent: 0,
        courseVisitedPercent: 0,
        courseTotalChildren: [],
    })

    const [lastSessionProgress, setLastSessionProgress] = useState(null)

    const loadCourseContent = async () => {
        try{
            if(!courseId){
                loadCourseContent()
                return
            }
            const { school_id } = JSON.parse(localStorage.getItem('metadata') || "{}")
            setSchoolId(school_id)
            const response = await new MembersAreaService().get(`student/course/${courseId}/${school_id}`)
            
            if(response || !response?.error){
                console.log('response', response)
                setMetadata(response)
    
                const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
    
                if(!isMobile){
                    if(response?.course?.courseImageDesktop){
                        setCover(response?.course?.courseImageDesktop?.url)
                    }
                }else{
                    if(response?.course?.courseImageMobile){
                        setCover(response?.course?.courseImageMobile?.url)
                    }else if(response?.course?.courseImageDesktop){
                        setCover(response?.course?.courseImageDesktop?.url)
                    }
                }
    
                const user = await new AuthService().getUserId()
                setUserId(user.user_id)
            }
        }catch(ex){
            console.error(ex)
            // document.location.reload() 
        }
    }
    const loadCourseProgress = async () => {
        const response = await new MembersAreaService().get(`student/get/course/progress/${courseId}`)
        if(response || !response?.error){
            setCourseProgress(response)
        }
    }
    const loadLastSessionProgress = async () => {
        const response = await new MembersAreaService().get(`student/get/progress/course/${courseId}`)
        if(response || !response?.error){
            setLastSessionProgress(response)
        }
    }

    const verifyIfCanGetCertificate = async () => {
        if(!courseId){
            verifyIfCanGetCertificate()
            return
        }
        const { school_id } = JSON.parse(localStorage.getItem('metadata') || "{}")
        const response = await new MembersAreaService().get(`certificate/verify/${school_id}/${courseId}`)
        if(response || !response?.error){
            setCanGetCertificate(response.result)
        }
    }

    const getVideoPreview = (video_id, lesson_progress) => {
        if(!video_id){
            return <div class="thumbnail-preview-mini"><small>Prévia não disponível</small></div>
        }
    
        return <div>
            <div class="thumbnail-preview-mini" 
                style={{backgroundImage: `URL('${process.env.REACT_APP_API_URL}/members/lesson/thumbnail/preview/${video_id}')`, backgroundSize: 'cover'}}
            >
                <Icon icon='play-circle' size='2x' className="play-button-circle" />
                
            </div>
            <div style={{position: 'relative', width: `${(lesson_progress?.watchTime * 100) / lesson_progress?.totalTime || 0}%`,  left: '0px', bottom: '0px'}}>
                    <div style={{backgroundColor: 'red', height: '5px', borderBottomLeftRadius: '3px'}}></div>
            </div>
        </div>
        
    }

    const openClassRoom = (moduleId, lessonId) => {
        if(lessonId){
            history.push(`/classroom/${courseId}/${moduleId}/${lessonId}`)
        }else{
            history.push(`/classroom/${courseId}/${moduleId}`)
        }
    }

    const resumeClassRoom = () => {
        if(lastSessionProgress){
            history.push(`/classroom/${courseId}/${lastSessionProgress?.module}/${lastSessionProgress?.lesson}`)
        }else{
            history.push(`/classroom/${courseId}/${metadata?.modules[0]?._id}`)
        }
    }

    const getContentCount = (payload) => {
        let text = ""
        if(payload){
            const videosCount = payload.filter(item => item?.lesson?.type === "video").length
            const documentsCount = payload.filter(item => item?.lesson?.type === "document").length
            const audioCount = payload.filter(item => item?.lesson?.type === "audio").length
            const exerciceCount = payload.filter(item => item?.exercice).length
    
            if(videosCount){
                text = `${text} ${videosCount} ${videosCount > 1 ? 'videos' : 'video'},`
            }
            if(documentsCount){
                text = `${text}  ${documentsCount} ${documentsCount > 1 ? 'documentos' : 'documento'},`
            }
            if(audioCount){
                text = `${text} ${audioCount} ${audioCount > 1 ? 'áudios' : 'áudio'},`
            }
            if(exerciceCount){
                text = `${text} ${exerciceCount} ${exerciceCount > 1 ? 'atividades' : 'atividade'}`
            }
            
            //4 vídeos, 3 exercícios, 1 prova
            return  text
        }
    }

    const getTotalCourseDuration = () => {
        let durationSeconds = 0

        if(metadata && metadata?.modules?.length){
            metadata.modules.forEach((module) => {
                if(module?.children?.length){
                    module.children.forEach((child)=>{
                        switch(child.onModel){
                            case "Lesson":
                                const type = child.lesson.type 
                                if(type === "video"){
                                    const videoDuration = child.lesson.videoSource.duration
                                    durationSeconds += videoDuration || 1800  // 30 minutes

                                }
                                if(type === "document"){
                                    durationSeconds += 1800 // 30 minutes
                                }

                                if(type === "audio"){
                                    const audioDuration = child.lesson.audioSource.duration
                                    durationSeconds += audioDuration || 600  // 10 minutes
                                }
                            break;
                            case "Exercice":
                                const exercice = child.exercice

                                if(exercice?.canRepeat){
                                    durationSeconds += 600
                                }else {
                                    durationSeconds += 1800
                                }
                            break;
                            default:
                            break;
                        }
                    })
                }
            })

        }

        return getFormatedTimeFromSeconds(durationSeconds)
    }

    useEffect(()=>{
        loadCourseContent()
        loadCourseProgress()
        loadLastSessionProgress()
        verifyIfCanGetCertificate()
    },[])

    return  <>
        {/* header */}
        <Row>
            <div class="container" style={cover ? {backgroundImage: `url('${cover}')` } : {}}>
                <div class="overlay"></div>
                <div class="cover-content">
                    {/* <Row>
                        <Col lg={24}> 
                            <div class="ledContainer">
                                <i class="recordLed live"></i><span class="liveText">&nbsp; AULA AO VIVO</span>
                            </div>
                        </Col>
                    </Row> */}

                    <Row style={{marginTop: '2.5em', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <div style={{marginRight: '25px'}}>
                            <Button className="course-cta-button" appearance='default' onClick={()=>resumeClassRoom()}><Icon icon="play"/> <span style={{marginLeft: '10px', fontWeight: 'bold'}}>{lastSessionProgress ? 'Continuar' : 'Assistir'}</span></Button>
                        </div>
                        {/* <div lg={2}>
                            <Button className="course-cta-button" appearance='default' color="red" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{'font-size': '0.6rem', marginRight: '10px', paddingBottom: '4px'}}>⬤</div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <small className="button-small-text" style={{fontWeight: 700}}>Aula ao vivo </small>
                                    <small className="button-small-text">16/03/2024 - 13:04 </small> 
                                    <small className="button-small-text">Clique para assistir </small>
                                </div>
                            </Button>
                        </div> */}
                        {/* <div style={{marginLeft: '55px'}}><IconButton className="like-button" appearance='ghost' circle icon={<Icon icon='thumbs-o-up' />} /></div>
                        <div style={{marginLeft: '10px'}}><IconButton className="like-button" appearance='ghost' circle icon={<Icon icon='thumbs-o-down' />} /></div> */}
                    </Row>
                </div>
            </div>
        </Row>
        {/*  end header */}
        {/* content */}
        <Container style={{padding: '0 4.5%'}}>
            <Row>
                <Col lg={18}>
                    <Row>
                        <Col lg={24} className='course-title'>
                            <h2>{metadata?.course?.name}</h2>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '45px'}}>
                        <Col lg={24}><h4 className='subtitle'>Sobre o Curso</h4></Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <div className='course-metadata'>
                                <span style={{fontWeight: 'bolder'}}>Carga Horária Aprox.</span>
                                <span style={{margin: '0 10px'}}>{getTotalCourseDuration()} </span>
                                <img src={hd} alt="High Definition" width="25px" />
                            </div>
                        </Col>
                    </Row>
                    <Row class="progress-bar-row" style={{marginTop: '15px'}}>
                        <Col lg={7}>
                            <div style={{position: 'absolute',  minWidth: '3%', width: '100%', left: '5px', bottom: '0px', display: 'flex', alignItems: 'center'}}>
                                <div className="progress-bar-theme" style={{minWidth: '4%', width: `${courseProgress?.courseDonePercent?.toFixed()}%`, height: '5px', borderRadius: '3px'}}></div> <span style={{color: '#ffffffab', fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>{courseProgress?.courseDonePercent?.toFixed()}%</span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>
                        <Col lg={24}>
                            <p>
                                {metadata?.course?.description || "Nenhuma descrição foi inserida"}
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col lg={7} style={{fontWeight: 'bold'}}>Professores: </Col>
                        <Col lg={17}><span className="text-light">{metadata?.course?.teachers}</span></Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>
                        <Col lg={7} style={{fontWeight: 'bold'}}>Categoria: </Col>
                        <Col lg={17}><span className="text-light">{metadata?.course?.category?.categoryName}</span></Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col lg={24}> <Button 
                        disabled={!canGetCertificate} 
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_API_URL}/members/certificate/generate/${schoolId}/${metadata?.course?._id}/${userId}`, '_blank')
                        }} appearance='ghost'><Icon icon="mortar-board" /> Obter meu Certificado</Button></Col>
                    </Row>
                </Col>
            </Row>
    
            <Row style={{marginTop: '25px'}}>
                <Col lg={24}><h4 className='subtitle'>Conteúdo deste Curso</h4></Col>
            </Row>
            <Row style={{marginTop: '25px', marginBottom: '10vh'}}>
                <Col lg={18}>
                    <Timeline className="custom-timeline">
                        {
                            metadata?.modules?.length ? metadata?.modules?.sort((a, b) => a?.order - b?.order).map((module, index) => {
                                return <>
                                <Timeline.Item dot={<Icon icon="mortar-board" size="2x" />}>
                                    {
                                        metadata?.course?.showSectionTitle ? <p><span class="titulo-modulo" style={{fontWeight: 600}}>Módulo {index + 1}:</span> {module?.name}</p> : <h6>{module?.name}</h6>
                                    }
                                    <p>{getContentCount(module.children)}</p>
                                    </Timeline.Item>
                                    <Timeline.Item>
                                    <Panel className="module-item"  defaultExpanded={index === 0 ? true : false} header={<Row style={{display: 'flex', alignItems: 'center'}}>
                                        <Col>
                                        Conteúdo deste módulo
                                        </Col>
                                        <Col lg={10}>
                                            <div style={{width: '70%', left: '5px', bottom: '0px', display: 'flex', alignItems: 'center'}}>
                                                <div className="progress-bar-theme" style={{marginLeft: '15px', minWidth: '3%', width: `${courseProgress?.modulesProgress?.find(_module => _module.module_id == module?._id)?.donePercent?.toFixed()}%` , height: '5px', borderRadius: '3px'}}></div> 
                                                <span style={{color: '#ffffffab', fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>{courseProgress?.modulesProgress?.find(_module => _module.module_id == module?._id)?.donePercent?.toFixed()}%</span>
                                            </div>
                                        </Col>
                                    </Row>} collapsible>
                                        {
                                            module.children.sort((a, b) => a?.order - b?.order).map((item, index) => { 
                                                return <>
                                                    <Row  className="lesson-row" style={{marginBottom: '25px', cursor: 'pointer'}} onClick={()=>openClassRoom(module._id, item._id)}>
                                                        {/* <Col lg={1}><Icon style={{color: 'rgba(0,0,0,0.4)'}} icon={'bars'} /></Col> */}
                                                        <Col lg={23}>
                                                            {
                                                                item?.lesson?.type === "video" ? <>
                                                                    <Row className="timeline-row">
                                                                        <div className="timeline-preview">
                                                                        {
                                                                            courseProgress?.modulesProgress ? 
                                                                            getVideoPreview(
                                                                                item?.lesson?.videoSource?.video_id, 
                                                                                courseProgress?.modulesProgress
                                                                                ?.find(_module => _module?.module_id === module?._id)?.progress
                                                                                ?.find(_item => _item?.lesson === item._id)
                                                                            ) : null
                                                                        }
                                                                        </div>
                                                                        <div style={{width: '100%'}}>
                                                                            {/* <Row>
                                                                                <Col lg={24}><small style={{color: 'rgba(255,255,255, 0.4)'}}>#{item._id}</small></Col>
                                                                            </Row> */}
                                                                            <Row className="lesson-title">
                                                                                <Col lg={24}><h6>{item?.lesson?.name}</h6></Col>
                                                                            </Row>
                                                                            <Row style={{marginTop: '5px'}}>
                                                                                <Col lg={24}>
                                                                                    <Row style={{marginTop: '5px'}}>
                                                                                        <Col lg={24}>
                                                                                            <p dangerouslySetInnerHTML={{__html: item?.lesson?.description}}></p>
                                                                                        </Col>
                                                                                    </Row>  
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Row>
                                                                </> : null
                                                            }

                                                            {
                                                                item?.lesson?.type === "audio" ? <>
                                                                <Row className="timeline-row">
                                                                    <div className="timeline-preview">
                                                                        <div className="thumbnail-preview-mini">
                                                                            <FontAwesomeIcon
                                                                                icon="file-audio"
                                                                                style={{ fontSize: '2em', color: 'rgba(0, 0, 0, 0.8)' }}
                                                                            />                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div style={{width: '100%'}}>
                                                                        {/* <Row>
                                                                            <Col lg={24}><small style={{color: 'rgba(255,255,255, 0.4)'}}>#{item._id}</small></Col>
                                                                        </Row> */}
                                                                        <Row  className="lesson-title">
                                                                            <Col lg={24}><h6>{item?.lesson?.name}</h6></Col>
                                                                        </Row>
                                                                        <Row style={{marginTop: '5px'}}>
                                                                            <Col lg={24}>
                                                                            <Row style={{marginTop: '5px'}}>
                                                                            <Col lg={24}>
                                                                                <p dangerouslySetInnerHTML={{__html: item?.lesson?.description}}></p>
                                                                            </Col>
                                                                            </Row>  
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <Col lg={2} style={{display: 'flex', justifyContent: 'space-around', alignContent: 'center'}}>
                                                                    </Col>
                                                                </Row>
                                                                </> : null
                                                            }

                                                            {
                                                                item?.lesson?.type === "document" ? <>
                                                                <Row className="timeline-row">
                                                                    <div className="timeline-preview">
                                                                        <div className="thumbnail-preview-mini">
                                                                            <FontAwesomeIcon
                                                                                icon="fa-file"                                                                                
                                                                                style={{ fontSize: '2em', color: 'rgba(0, 0, 0, 0.8)' }}
                                                                            />                                                                                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div style={{width: '100%'}}>
                                                                        {/* <Row>
                                                                            <Col lg={24}><small style={{color: 'rgba(255,255,255, 0.4)'}}>#{item._id}</small></Col>
                                                                        </Row> */}
                                                                        <Row className="lesson-title">
                                                                            <Col lg={24}><h6>{item?.lesson?.name}</h6></Col>
                                                                        </Row>
                                                                        <Row style={{marginTop: '5px'}}>
                                                                            <Col lg={24}>
                                                                            <Row style={{marginTop: '5px'}}>
                                                                                <Col lg={24}>
                                                                                    <p dangerouslySetInnerHTML={{__html: item?.lesson?.description}}></p>
                                                                                </Col>
                                                                            </Row>  
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <Col lg={2} style={{display: 'flex', justifyContent: 'space-around', alignContent: 'center'}}>
                                                                    </Col>
                                                                </Row>
                                                                </> : null
                                                            }

                                                            {
                                                                item?.exercice ? <>
                                                                    <Row className="timeline-row">
                                                                        <div className="timeline-preview">
                                                                            <div className="thumbnail-preview-mini">
                                                                                <FontAwesomeIcon
                                                                                    icon="fa-file-circle-question"                                                                                
                                                                                    style={{ fontSize: '2em', color: 'rgba(0, 0, 0, 0.8)' }}
                                                                                />                                                                                                                                        
                                                                            </div>
                                                                        </div>
                                                                        <div style={{width: '100%'}}>
                                                                            {/* <Row>
                                                                                <Col lg={24}><small style={{color: 'rgba(255,255,255, 0.4)'}}>#{item._id}</small></Col>
                                                                            </Row> */}
                                                                            <Row className="lesson-title">
                                                                                <Col lg={24}><h6>{item?.exercice?.name}</h6></Col>
                                                                            </Row>
                                                                            <Row style={{marginTop: '5px'}}>
                                                                                <Col lg={24}>
                                                                                    <Row style={{marginTop: '5px'}}>
                                                                                        <Col lg={24}>
                                                                                            <p dangerouslySetInnerHTML={{__html: item?.exercice?.description}}></p>
                                                                                        </Col>
                                                                                    </Row>  
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <Col lg={2} style={{display: 'flex', justifyContent: 'space-around', alignContent: 'center'}}>
                                                                        </Col>
                                                                    </Row>
                                                                </> : null
                                                            }

                                                            {/* <p>{item.type}</p> */}
                                                            </Col>
                                                        </Row>
                                                </>
                                            })
                                        }
                                    </Panel>
                                </Timeline.Item>
                                </>
                            }) : null
                        } 
                        {/* <Timeline.Item
                        dot={
                            <Icon
                            icon="check"
                            size="2x"
                            style={{ background: '#15b215', color: '#fff' }}
                            />
                        }
                        >
                        <p>March 3, 17:50</p>
                        <p>[Received]]</p>
                        <p>Your courier has arrived and the signer is the front desk</p>
                        </Timeline.Item> */}
                        <Timeline.Item
                        dot={
                            <Icon
                            icon="flag-checkered"
                            size="2x"
                            style={{  color: '#fff' }}
                            />
                        }
                        >
                        <p style={{paddingTop: '15px', fontWeight: '600'}}>Conclusão do Curso</p>
                        </Timeline.Item>
                    </Timeline>
                </Col>
            </Row>
        </Container>
        {/* end content */}
    </>
}

export default CoursePage;
